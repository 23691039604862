<template>
    <div>
        <v-container>
          <v-card style="background-color: #F5F5F5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                <span v-if="isNew">Nueva</span>
                <span v-if="!isNew">Editar</span>
                solicitud de material
            </div>

            <v-row v-if="isNew">
                <v-col class="px-7 mt-2" cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-radio-group v-model="isDevol" :readonly="respReadonly" hide-details row>
                      <v-radio
                          label="Solicitud"
                          :value="false">
                      </v-radio>
                      <v-radio
                          label="Devolución"
                          :value="true">
                      </v-radio>
                  </v-radio-group>
                </v-col>
            </v-row>
            <!-- Solo puede elegir el empleado RRHH -->
            <v-row v-if="$store.state.esRRHH">
                <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                    <PerFind class="mb-n7" ref="perFind" v-model="sol.Per" :readonly="respReadonly" :isRrhh="$store.state.esRRHH" label="Empleado" :perCatIdAnyLevel="101"></PerFind>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="px-7 mt-2" cols="12" sm="6" md="5" lg="5" xl="4">
                    <MatFind class="mb-n7" ref="matCtrl" v-model="sol.Mat" label="Material" @change="onChangeMat"></MatFind>
                    <!-- :readonly="respReadonly" -->
                </v-col>
            </v-row>
            <v-row v-show="sol.Mat != null && sol.Mat.MatVarId1 != null">
                <v-col class="px-7 mt-2" cols="12" sm="5" md="4" lg="3" xl="2">
                    <MatVarFind 
                        class="mb-n7" ref="matVar1Ctrl" 
                        v-model="sol.MatVar1"
                        :label="MatVar1Nom" 
                        :matVarIdParent="sol.Mat != null ? sol.Mat.MatVarId1 : null">
                    </MatVarFind>
                    <!-- :readonly="respReadonly" -->
                </v-col>
            </v-row>
            <v-row v-show="sol.Mat != null && sol.Mat.MatVarId2 != null">
                <v-col class="px-7 mt-2" cols="12" sm="5" md="4" lg="3" xl="2">
                    <MatVarFind 
                        class="mb-n7" ref="matVar2Ctrl" 
                        v-model="sol.MatVar2"
                        :label="MatVar2Nom" 
                        :matVarIdParent="sol.Mat != null ? sol.Mat.MatVarId2 : null">
                    </MatVarFind>
                    <!-- :readonly="respReadonly" -->
                </v-col>
            </v-row>
            <v-row>
                <v-col class="px-7 mt-2" cols="12" sm="7" md="6" lg="6" xl="5">
                    <DirFind class="mb-n7" ref="dirCtrl" v-model="sol.Dir" :readonly="respReadonly" label="Dirección de entrega"></DirFind>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="px-7 mt-2" cols="12" sm="4" md="3" lg="2" xl="2">
                    <v-text-field
                        class="mb-n7"
                        outlined
                        dense
                        type="number"
                        min="0"
                        v-model="sol.SolCant"
                        label="Cantidad">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                  <v-textarea
                      class="mt-0 mb-n7"
                      outlined
                      auto-grow
                      v-model="sol.SolObs"
                      :readonly="respReadonly"
                      label="Observaciones">
                  </v-textarea>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px">
                    Aceptar
                </v-btn>
                
                <v-btn
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                    @click="goToList">
                    Cancelar
                </v-btn>
            </v-row>
          </v-card>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DirFind from "../components/DirFind.vue";
import MatFind from "../components/MatFind.vue";
import MatVarFind from "../components/MatVarFind.vue";
import PerFind from "../components/PerFind.vue";
export default ({
    components: {
        DirFind, MatFind, MatVarFind, PerFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'nombreUsuario', 'urlRaiz']),
        // Comprueba si se aplica el readonly para RRHH
        respReadonly: function () {
          return this.$store.state.esRRHH && !this.isNew;
        },
        MatVar1Nom: function () {
            if (this.sol.Mat == null || this.sol.Mat.MatVar1 == null) { return 'Variante 1'; }
            return 'Variante - ' + this.sol.Mat.MatVar1.MatVarNom;
        },
        MatVar2Nom: function () {
            if (this.sol.Mat == null || this.sol.Mat.MatVar2 == null) { return 'Variante 2'; }
            return 'Variante - ' + this.sol.Mat.MatVar2.MatVarNom;
        }
    },
    data () {
        return {
            loading: false,
            isNew: true,
            isValid: false,
            sol: {
                SolId: null,
                Per: null,
                Dir: null,
                Mat: null,
                MatVar1: {},
                MatVar2: {},
                SolCant: null,
                SolObs: ''
            },
            isDevol: false
        }
    },
    watch: {
      sol: {
        handler () {
            this.sol.DirId = this.sol.Dir != null ?
                this.sol.Dir.DirId : null;
            this.sol.MatId = this.sol.Mat != null ?
                this.sol.Mat.MatId : null;
            this.sol.PerId = this.sol.Per != null ?
                this.sol.Per.PerId : null;
            this.validate();
        },
        deep: true,
      }
    },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      if(!this.isNew) {
          this.sol.SolId = this.$route.params.id;
          this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Solicitud no encontrada.');
              this.goToList();
              return;
            }
            this.sol = data.item;
          });
      } else {
        if(!this.$store.state.esRRHH) {
          // Init. Per
          if(this.perId != null && this.perId != 0) {
            this.sol.PerId = this.perId;
            this.sol.Per = {
                PerId: this.perId,
                PerNom: this.nombreUsuario
            }
          }
        }

        this.sol.SolCant = 1;
      }
    },
    methods: {
      goToList() {
        if(this.$store.state.esRRHH) {
          this.$router.push('/solicitud-material-responsable');
        } else {
          this.$router.push('/solicitud-material');
        }
      },
      getDataFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_SOL',
            SolId: this.sol.SolId,
            PerId: this.perId,
            IsResp: this.$store.state.esRRHH,
            IncludeSolSta: false
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Sol;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la solicitud.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            SolId: this.sol.SolId,
            PerId: this.sol.PerId,
            IsDevol: this.isDevol,
            MatId: this.sol.MatId,
            MatVarId1: this.sol.MatVar1 != null ? this.sol.MatVar1.MatVarId : null,
            MatVarId2: this.sol.MatVar2 != null ? this.sol.MatVar2.MatVarId : null,
            DirId: this.sol.DirId,
            SolCant: this.sol.SolCant,
            SolObs: this.sol.SolObs
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la solicitud.');
            }
          });
        })
      },
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.SolId != null) {
                this.goToList();
            }
          });
      },
      onChangeMat() {
        this.sol.MatVar1 = {};
        this.sol.MatVar2 = {};
      },
      validate() {
          if (this.sol == null) {
              this.isValid = false;
              return;
          }
          this.isValid = this.sol.DirId != null && this.sol.MatId != null && this.sol.PerId != null;
      }
    } 
})
</script>
